import React, { Box, Container, Flex, Heading, Text } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"

const FailurePage = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title="Payment Status" />
      <Container textAlign="center" py="32">
        <Box my="16">
          <Flex justifyContent="center">
            <Heading textAlign="center" w="50%">
              {t("title")}
            </Heading>
          </Flex>
          <Text
            textAlign="center"
            my="10"
            color="gray.400"
            maxW="350px"
            mx="auto"
          >
            {t("description")}
          </Text>
        </Box>
      </Container>
    </Layout>
  )
}

export default FailurePage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "payment_failure"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
